import React from 'react';
import PropTypes from 'prop-types';
import Clickable from 'components/clickable';

import s from './DocumentationDetail.scss';

const DocumentationDetail = ({ heading, list }) => (
  <div className={s.documentationDetail}>
    <h4 className={s.documentationDetail__heading}>{heading}</h4>
    <ul className={s.documentationDetail__list}>
      {list.map((item, i) => {
        if (item.heading) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${s.documentationDetail}-${i}`} className={s.documentationDetail__item}>
              <Clickable className={s.documentationDetail__link} to={item.link || '#'}>{item.heading}</Clickable>
            </li>
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${s.documentationDetail}-${i}`} className={s.documentationDetail__item}>{item}</li>
        );
      })}
    </ul>
  </div>
);

DocumentationDetail.propTypes = {
  heading: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DocumentationDetail;
