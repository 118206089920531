import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Clickable from 'components/clickable';

import s from './ArticleCard.scss';

export default class ArticleCard extends PureComponent {

  static propTypes = {
    isGlobalSite: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    source: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isGlobalSite: true,
    source: null,
    image: null,
  }

  render() {
    const { heading, source, image, link, isGlobalSite } = this.props;
    const IMAGE_WIDTH = 390;

    return (
      <div className={s.articleCard}>
        {image &&
          <div className={s.articleCard__imageWrap}>
            <div className={s.articleCard__image}>
              <Image
                src={`${image}?w=${IMAGE_WIDTH}`}
                src2x={`${image}?w=${IMAGE_WIDTH * 2}`}
              />
            </div>
          </div>
        }
        <div className={s.articleCard__content}>
          <p className={s.articleCard__source}>{source}</p>
          <h5 className={s.articleCard__heading}>{heading}</h5>
        </div>
        <Clickable to={link} className={s.articleCard__link}>
          {isGlobalSite ? 'Read' : '阅读更多'}
        </Clickable>
      </div>
    );
  }
}
