import React from 'react';
import PropTypes from 'prop-types';

import s from './DocumentNavigation.scss';

const DocumentNavigation = ({ children }) => (
  <div className={s.documentNavigation}>
    {children}
  </div>
);

DocumentNavigation.propTypes = {
  children: PropTypes.node,
};

DocumentNavigation.defaultProps = {
  children: undefined,
};

export default DocumentNavigation;
