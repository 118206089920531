import { graphql } from 'gatsby';
import Developer from 'routes/developer/Developer';

export default Developer;

export const query = graphql`
  fragment example on ContentfulDocumentationExample {
    topic
    heading: title
    link
  }

  fragment documentation on ContentfulDocumentationSection {
    image {
      ...image
    }
    heading: title
    text: body {
      text: body
    }
    ctaUrl
    docsAvailable {
      ...example
    }
    docsComing
    examples {
      ...example
    }
  }

  query Developer {
    page: contentfulPageDeveloper {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroImage {
        ...image
      }
      documentation {
        ...documentation
      }
      navText {
        text: navText
      }
      cta {
        ...moduleList
      }
    }
  }
`;
