import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ViewportEnter from 'components/viewport-enter';

import s from './DocumentSection.scss';

export default class DocumentSection extends PureComponent {

  static propTypes = {
    index: PropTypes.number,
    onEnter: PropTypes.func,
    children: PropTypes.node,
    id: PropTypes.string,
  }

  onEnter = (i) => {
    this.props.onEnter(i);
  }

  render() {
    const { index, children, id } = this.props;

    return (
      <ViewportEnter threshold={0} once={false} onEnter={() => { this.onEnter(index); }}>
        <div className={s.documentSection} id={id}>
          {children}
        </div>
      </ViewportEnter>
    );
  }
}
