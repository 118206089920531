import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';

import extractMeta from 'utils/extract-meta';

import { withUIContext } from 'context/ui';

import DocumentGroup from 'components/document-group';
import Modules from 'containers/modules';

import GreyArea from 'components/grey-area';

import DeveloperHero from './components/hero';
import Documentation from './components/documentation';
import SEO from 'components/seo';

const hasLetters = text => text.search(/[A-Za-z]+/) >= 0 
class Developer extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('light');
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    const sections = page.documentation.map(doc => doc.heading);

    const isGlobalSite = hasLetters(page.pageTitle);

    return (
      <Fragment>
        <SEO pathname={pathname} article {...extractMeta(page)}/>
        <DeveloperHero
          heading={page.heroHeading}
          text={page.heroText.text}
          poster={page.heroImage.file.url}
          theme="light"
        />

        <GreyArea>
          <DocumentGroup sections={sections} additionalNavText={page.navText.text}>
            {page.documentation.map(doc => (
              <Documentation
                isGlobalSite={isGlobalSite}
                key={doc.heading}
                heading={doc.heading}
                text={doc.text.text}
                cta={{
                  text: isGlobalSite ? 'View Docs' : '开发文档',
                  url: doc.ctaUrl,
                }}
                image={{
                  src: _get(doc.image, 'file.url'),
                  alt: _get(doc.image, 'description'),
                  width: _get(doc.image, 'file.details.image.width'),
                  height: _get(doc.image, 'file.details.image.height'),
                }}
                docsAvailable={doc.docsAvailable}
                docsComing={doc.docsComing}
                examples={doc.examples}
              />
            ))}

          </DocumentGroup>
        </GreyArea>
        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}

export default withUIContext(Developer);
