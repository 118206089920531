import React, { Fragment, Component, Children } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Markdown from 'components/markdown';

import { Container, Row } from 'components/layout';

import { DocumentSection, DocumentNavigation, DocumentNavigationItem } from '.';

import s from './DocumentGroup.scss';

export default class DocumentGroup extends Component {

  state = {
    activeSection: 0,
  }

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    sections: PropTypes.array,
    heading: PropTypes.string,
    text: PropTypes.string,
    additionalNavText: PropTypes.string,
    id: PropTypes.string, // if a unique ID is required
  }

  static defaultProps = {
    sections: [],
    id: 'document-group',
  }

  onUpdate = (i) => {
    this.setState({
      activeSection: i,
    });
  }

  render() {
    const { sections, className, id, children, heading, text, additionalNavText } = this.props;

    return (
      <Fragment>
        <Helmet htmlAttributes={{ class: 'smooth-scroll' }} />

        <section className={s('documentGroup', className)}>
          <Container>
            <Row>
              <div className={s.documentGroup__sidebar}>
                <DocumentNavigation>
                  {sections.map((section, i) => (
                    <DocumentNavigationItem
                      key={`${s.documentGroup}-${section}`}
                      index={i}
                      text={section}
                      active={i === this.state.activeSection}
                      id={`${id}-${i}`}
                    />
                  ))}
                </DocumentNavigation>
                {additionalNavText &&
                  <Markdown source={additionalNavText} className={s.documentGroup__sidebarText}/>
                }
              </div>

              <div className={s.documentGroup__content}>
                { heading && (
                  <div className={s.documentGroup__intro}>
                    <h1 className={s.documentGroup__heading}>{heading}</h1>
                    {text && (<Markdown source={text} />)}
                  </div>
                )}
                {Children.map(children, (child, i) => (
                  <DocumentSection
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${s.documentGroup}-${i}`}
                    index={i}
                    id={`${id}-${i}`}
                    onEnter={this.onUpdate}
                  >
                    {child}
                  </DocumentSection>
                ))}
              </div>
            </Row>
          </Container>
        </section>
      </Fragment>
    );
  }
}
