import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TimelineLite } from 'gsap';

import { Container } from 'components/layout';

import s from './DeveloperHero.scss';

export default class Hero extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    poster: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: '',
  }

  componentDidMount() {
    this.animate();
  }

  animate = () => {
    const contentItems = this.content.childNodes;

    this.t = new TimelineLite();

    this.t.addLabel('start');
    this.t.fromTo(this.heroInner, 1, { autoAlpha: 0 }, { autoAlpha: 1 });
    this.t.staggerFromTo(contentItems, 1, { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 }, 0.2, 'start');
  }

  render() {
    const { heading, text, poster } = this.props;

    return (
      <div className={s('hero')}>
        <div className={s.hero__inner} ref={(el) => { this.heroInner = el; }} >
          <div className={s.hero__contentWrap}>
            <Container>
              <div ref={(el) => { this.content = el; }}>
                <h1 className={s.hero__heading}>{heading}</h1>
                {text && (<p className={s.hero__text}>{text}</p>)}
              </div>
            </Container>
          </div>
          {poster && (
            <div
              className={s.hero__background}
              style={{ backgroundImage: `url('${poster}')` }}
            />
          )}
        </div>
      </div>
    );
  }
}
