import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import s from './DocumentNavigation.scss';

const DocumentNavigationItem = ({ text, id, active, href, onClick }) => (
  <div className={s('documentNavigation__item', { active })}>
    <a 
      className={s.documentNavigation__link} 
      href={
        href 
        ? href 
        : id >= 0 
          ? `#${id}`
          : ``}
      onClick={onClick}>{text}
      { active && (
        <span className={s.documentNavigation__arrow}>
          <Icon id="arrow-rounded" />
        </span>
      )}
    </a>
  </div>
);

DocumentNavigationItem.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

DocumentNavigationItem.defaultProps = {
  href: undefined,
  onClick: () => {}
};

export default DocumentNavigationItem;
